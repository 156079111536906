.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.store-footer {
  background-color: #8b0000;
}

.home .div-2 {
  background-color: #ffffff;
  height: 3589px;
  overflow: hidden;
  position: relative;
  width: 1445px;
}

.home .overlap {
  height: 592px;
  left: 0;
  position: absolute;
  top: 2261px;
  width: 1445px;
}

.home .rectangle {
  background-color: #d9d9d9;
  height: 592px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.home .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 27px;
  left: 54px;
  position: absolute;
  top: 501px;
}

.home .rectangle-2 {
  background-color: #000000;
  border-radius: 30px;
  height: 36px;
  position: relative;
  width: 249px;
}

.home .rectangle-3 {
  background-color: #49494980;
  border: 1px solid;
  border-color: #000000;
  border-radius: 30px;
  height: 36px;
  position: relative;
  width: 33px;
}

.home .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  left: 54px;
  position: absolute;
  top: 81px;
}

.home .text-wrapper-4 {
  color: #000000;
  font-family: "Inter-Black", Helvetica;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home .text-wrapper-5 {
  color: #000000;
  font-family: "Inter-Black", Helvetica;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.home .rectangle-4 {
  height: 272px;
  left: 357px;
  position: absolute;
  top: 176px;
  width: 240px;
}

.home .rectangle-5 {
  height: 543px;
  left: 933px;
  position: absolute;
  top: 18px;
  width: 342px;
}

.home .rectangle-6 {
  height: 574px;
  left: 1307px;
  position: absolute;
  top: 18px;
  width: 138px;
}

.home .rectangle-7 {
  height: 174px;
  left: 631px;
  position: absolute;
  top: 274px;
  width: 256px;
}

.home .div-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #000000;
  border-radius: 40px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 578px;
  padding: 20px 60px;
  position: absolute;
  top: 477px;
  width: 309px;
}

.home .frame-3 {
  border: 1px solid;
  border-color: #000000;
  border-radius: 40px;
  height: 84px;
  left: 471px;
  position: absolute;
  top: 477px;
  width: 92px;
}

.home .overlap-2 {
  background-color: #f3f3f3;
  box-shadow: 0px 0px 5px 8px #00000040;
  height: 109px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1445px;
}

.home .text-wrapper-6 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1359px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
}

.home .text-wrapper-7 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1284px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
  transition: all 0.2s ease;
}

.home .search-text-field {
  background-color: #ffffff;
  border-radius: 30px;
  height: 43px;
  left: 308px;
  position: absolute;
  top: 33px;
  width: 335px;
}

.home .icon-wrapper {
  background-color: #d9d9d9;
  border-radius: 17.5px;
  height: 35px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 35px;
}

.home .icon {
  height: 27px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 27px;
}

.home .text-wrapper-8 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1177px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
}

.home .text-wrapper-9 {
  color: #000000;
  font-family: "Inter-Black", Helvetica;
  font-size: 24px;
  font-weight: 900;
  left: 104px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
}

.home .ellipse {
  background-color: #ffffff;
  border-radius: 40.5px;
  height: 81px;
  left: 15px;
  position: absolute;
  top: 14px;
  width: 81px;
}

.home .cart-img {
  height: 67px;
  left: 688px;
  position: absolute;
  top: 21px;
  transition: all 0.2s ease;
  width: 59px;
}

.home .overlap-3 {
  height: 67px;
  position: relative;
  width: 55px;
}

.home .text-wrapper-10 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 13px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 52px;
}

.home .ico {
  height: 48px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 48px;
}

.home .label-bg {
  background-color: #ffffff;
  border-radius: 16px;
  height: 32px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 32px;
}

.home .label {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 9px;
}

.home .order-img {
  height: 64px;
  left: 770px;
  position: absolute;
  top: 24px;
  transition: all 0.2s ease;
  width: 55px;
}

.home .text-wrapper-11 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 2px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.home .overlap-4 {
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  width: 51px;
}

.home .bag {
  height: 41px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 41px;
}

.home .ordere-label-bg {
  background-color: #ffffff;
  border-radius: 15.5px;
  height: 31px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 31px;
}

.home .order-label {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
}

.home .overlap-5 {
  background-color: #f3f3f3;
  height: 482px;
  left: 0;
  position: absolute;
  top: 129px;
  width: 1445px;
}

.home .hand-bag-instance {
  /* background-image: url(./hand-bag-2.png) !important; */
  left: 684px !important;
  position: absolute !important;
  top: 8px !important;
}

.home .women-hand-bag-instance {
  left: 43px !important;
  position: absolute !important;
  top: 64px !important;
}

.home .design-component-instance-node {
  color: #c5943391 !important;
  font-family: "Inter-Black", Helvetica !important;
  font-weight: 900 !important;
}

.home .deal-30-flat {
  left: 55px !important;
  position: absolute !important;
  top: 194px !important;
}

.home .deal-flat-instance {
  font-family: "Inter-ExtraBold", Helvetica !important;
  font-weight: 800 !important;
}

.home .shop-now-instance {
  left: 55px !important;
  position: absolute !important;
  top: 314px !important;
}

.home .shop-now-2 {
  font-family: "Inter-Thin", Helvetica !important;
  font-weight: 100 !important;
}

.home .overlap-6 {
  background-color: #f3f3f3;
  height: 1527px;
  left: 0;
  position: absolute;
  top: 734px;
  width: 1445px;
}

.home .item-holder-instance {
  left: 41px !important;
  position: absolute !important;
  top: 32px !important;
}

.home .item-holder-2 {
  left: 41px !important;
  position: absolute !important;
  top: 541px !important;
}

.home .overlap-7 {
  height: 428px;
  left: 41px;
  position: absolute;
  top: 1050px;
  width: 318px;
}

.home .item-holder-3 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.home .vector-2 {
  height: 32px;
  left: 244px;
  position: absolute;
  top: 396px;
  width: 32px;
}

.home .vector-3 {
  height: 32px;
  left: 194px;
  position: absolute;
  top: 396px;
  width: 32px;
}

.home .vector-4 {
  height: 32px;
  left: 144px;
  position: absolute;
  top: 396px;
  width: 32px;
}

.home .vector-5 {
  height: 32px;
  left: 93px;
  position: absolute;
  top: 396px;
  width: 32px;
}

.home .vector-6 {
  height: 32px;
  left: 43px;
  position: absolute;
  top: 396px;
  width: 32px;
}

.home .item-holder-4 {
  left: 388px !important;
  position: absolute !important;
  top: 32px !important;
}

.home .item-holder-5 {
  left: 388px !important;
  position: absolute !important;
  top: 541px !important;
}

.home .item-holder-6 {
  left: 388px !important;
  position: absolute !important;
  top: 1050px !important;
}

.home .item-holder-7 {
  object-fit: unset !important;
}

.home .icon-instance-node {
  height: 32px !important;
  left: 43px !important;
  position: absolute !important;
  top: 396px !important;
  width: 232px !important;
}

.home .item-holder-8 {
  left: 735px !important;
  position: absolute !important;
  top: 32px !important;
}

.home .item-holder-9 {
  left: 735px !important;
  position: absolute !important;
  top: 541px !important;
}

.home .item-holder-10 {
  left: 735px !important;
  position: absolute !important;
  top: 1050px !important;
}

.home .item-holder-11 {
  left: 1082px !important;
  position: absolute !important;
  top: 32px !important;
}

.home .item-holder-12 {
  left: 1082px !important;
  position: absolute !important;
  top: 541px !important;
}

.home .item-holder-13 {
  left: 1082px !important;
  position: absolute !important;
  top: 1050px !important;
}

.home .products-bar {
  height: 102px;
  left: 0;
  position: absolute;
  top: 611px;
  width: 1446px;
}

.home .overlap-8 {
  background-color: #ffffff;
  height: 102px;
  position: relative;
  width: 1440px;
}

.home .frame-4 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #000000;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 910px;
  padding: 10px;
  position: absolute;
  top: 20px;
}

.home .text-wrapper-12 {
  color: #000000;
  font-family: "Open Sans-SemiBold", Helvetica;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home .text-wrapper-13 {
  color: #000000;
  font-family: "Open Sans-ExtraBold", Helvetica;
  font-size: 30px;
  font-weight: 800;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.home .text-wrapper-14 {
  color: #000000;
  font-family: "Open Sans-SemiBold", Helvetica;
  font-size: 30px;
  font-weight: 600;
  left: 1090px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.home .text-wrapper-15 {
  color: #000000;
  font-family: "Open Sans-SemiBold", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 1247px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 29px;
  transition: all 0.2s ease;
}

.home .category {
  height: 36px;
  left: 315px;
  position: absolute;
  top: 33px;
  transition: all 0.2s ease;
  width: 228px;
}

.home .text-wrapper-16 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home .rectangle-8 {
  height: 31px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 37px;
}

.home .icon-chevron-bottom {
  height: 10px;
  left: 211px;
  position: absolute;
  top: 13px;
  width: 15px;
}

.home .footer {
  background-color: #8b0000;
  height: 581px;
  left: 0;
  position: absolute;
  top: 3008px;
  width: 100%;
}

.home .FOUDHAN-STORE-instance {
  left: 170px !important;
  position: absolute !important;
  top: 93px !important;
}

.home .foudhan-store-is-the {
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 180px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 186px;
}

.home .frame-5 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 40px;
  height: 84px;
  left: 31px;
  position: absolute;
  top: 103px;
  width: 92px;
}

.home .frame-wrapper {
  background-color: #000000;
  height: 73px;
  left: 203px;
  position: absolute;
  top: 385px;
  width: 309px;
}

.home .frame-6 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 40px;
  display: flex;
  gap: 10px;
  height: 66px;
  justify-content: center;
  padding: 20px 60px;
  position: relative;
  top: 4px;
  width: 309px;
}

.home .text-wrapper-17 {
  color: #800000;
  font-family: "Inter-Black", Helvetica;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -8px;
  margin-left: -32.5px;
  margin-right: -32.5px;
  margin-top: -10px;
  position: relative;
  width: fit-content;
}

.home .text-wrapper-18 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 24px;
  font-weight: 900;
  left: 745px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 121px;
}

.home .women-cloths-watches {
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 745px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 186px;
}

.home .about-us-home-sign {
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 1009px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 186px;
}

.home .text-wrapper-19 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 20px;
  font-weight: 900;
  left: 1196px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 174px;
  white-space: nowrap;
}

.home .vector-7 {
  height: 32px;
  left: 1200px;
  position: absolute;
  top: 219px;
  width: 32px;
}

.home .text-wrapper-20 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 222px;
  white-space: nowrap;
}

.home .vector-8 {
  height: 32px;
  left: 1200px;
  position: absolute;
  top: 278px;
  width: 32px;
}

.home .text-wrapper-21 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 281px;
  white-space: nowrap;
}

.home .vector-9 {
  height: 32px;
  left: 1200px;
  position: absolute;
  top: 338px;
  width: 32px;
}

.home .text-wrapper-22 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 341px;
  white-space: nowrap;
}
