.item-card-bg,
.itm-img-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.item-card-bg {
  margin: 0 !important;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #f1ecec;
}
.itm-img-icon {
  object-fit: cover;
  z-index: 1;
}
.vector-icon {
  position: absolute;
  top: 0;
  left: 315px;
  width: 50px;
  height: 46.2px;
  z-index: 2;
}
.itm-img-parent {
  align-self: stretch;
  height: 317px;
  position: relative;
}

.price {
  font-size: 16px;
  font-weight: 900;
}

.price,
.short-desc {
  position: relative;
  z-index: 1;
}
.short-desc {
  align-self: stretch;
}
.price {
  white-space: nowrap;
}
.shippers,
.shippers-label {
  position: relative;
  /* font-weight: 900; */
  display: inline-block;
  min-width: 67px;
}
.shippers {
  min-width: 118px;
}
.price-parent,
.shipper-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.shipper-group {
  flex-direction: row;
  gap: 14px;
  z-index: 1;
  font-size: var(--font-size-sm);
}
.price-parent {
  flex-direction: column;
  gap: 2px;
}
.shipping-fee,
.shipping-fee-label {
  position: relative;
  /* font-weight: 400; */
  display: inline-block;
  min-width: 92px;
}
.shipping-fee {
  min-width: 29px;
}
.fees-group,
.frame-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.fees-group {
  flex-direction: row;
  gap: var(--gap-lgi);
  z-index: 1;
  font-size: var(--font-size-sm);
}
.frame-parent {
  flex-direction: column;
  padding: 0 0 5px;
  gap: 13px;
  font-size: 14px;
}
.text {
  position: relative;
  font-size: 12px;
  font-family: var(--font-inter);
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 38px;
}
.button,
.button-instance {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button {
  cursor: pointer;
  border: 1px solid var(--color-black);
  padding: 13px 20px;
  background-color: black;
  width: 238px;
  border-radius: 30px;
  box-sizing: border-box;
  justify-content: center;
  z-index: 1;
}
.button:hover {
  background-color: var(--color-darkslategray);
  border: 1px solid var(--color-darkslategray);
  box-sizing: border-box;
}
.button-instance {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 31px;
}
.item-card,
.short-desc-parent {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.short-desc-parent {
  width: 333px;
  gap: 24px;
  text-align: left;
  font-size: 14px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
.item-card {
  width: 330px;
  padding: 29px;
  margin: 10px;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-lgi);
  line-height: normal;
  letter-spacing: normal;
}
